import axios from 'axios'

const API_URL_AUTHORIZATION =
  'https://34n4yj3roj.execute-api.sa-east-1.amazonaws.com/authentication'
const API_URL =
  'https://va9k8w78u7.execute-api.sa-east-1.amazonaws.com/forms-management'
//const API_URL = 'https://va9k8w78u7.execute-api.sa-east-1.amazonaws.com/forms-management-emblue'

export default async function postForm(valuesRequired) {
  const data = await axios.post(API_URL_AUTHORIZATION, '', {
    headers: { secretKey: '2#ak!U$*5csd7vo2!T3$&a1q9n3!N2b5ik' }
  })

  const response = await axios({
    method: 'post',
    url: API_URL,
    headers: {
      Authorization: data.data
    },
    data: valuesRequired
  })

  return response
}